.quote {
  position: absolute;
  top: 50%;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.writer {
  color: white;
  font-size: 1.5rem;
}