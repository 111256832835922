* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background: linear-gradient(to right, #ff7301, #9803fc);
  box-sizing: border-box;
  padding: 24px;
}

#root {
  height: 80vh;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  margin-top: 100px;
}

h2 {
  font-size: 2rem;
}

.calculator-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.buttons-container {
  display: flex;
}

.grayBtn {
  display: grid;
  grid-template-columns: repeat(3, 7rem);
  grid-template-rows: repeat(5, 4rem);
}

.orangeBtn {
  display: grid;
  grid-template-columns: repeat(1, 7rem);
  grid-template-rows: repeat(5, 4rem);
}

.output {
  background-color: #858693;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 1rem;
  color: white;
}

.orangebg button {
  background-color: #f5913e;
  border: 1px solid #858693;
}

.graybg button {
  background-color: #e0e0e0;
  border: 1px solid #858693;
}

.orangebg button:hover {
  background-color: #6579ee;
}

.graybg button:hover {
  background-color: #fd6464;
}

.span-two {
  grid-column: span 2;
}
